/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/idb@8.0.1/build/umd.js
 * - /npm/tailwindcss-cdn@3.4.10/tailwindcss.min.js
 * - /npm/flowbite@3.0.0/dist/flowbite.min.js
 * - /npm/fuse.js@7.0.0/dist/fuse.min.js
 * - /npm/swiper@11.2.1/swiper-bundle.min.js
 * - /npm/vanilla-lazyload@19.1.3/dist/lazyload.min.js
 * - /npm/@hotwired/turbo@8.0.12/dist/turbo.es2017-umd.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
